<div class="search-bar" [class.show-results]="isSearchResultVisible$ | async" #searchBar>
  <coin-v2-text-input
    icon="search"
    size="medium"
    name="search"
    id="search"
    placeholder="{{ placeholder | translate }}"
    autocomplete="off"
    [(ngModel)]="searchInput"
    [clearable]="searchInput"
    #searchInputElement />
</div>

<div [class.show]="(isSearchResultVisible$ | async) ? 1 : 0" class="results" #searchResults>
  <div class="scroll-container">
    <coin-group-search-results
      [resultCacheKey]="resultCacheKey"
      [searchResultGroups]="searchResultGroups"
      [searchString]="searchInput"
      (initializeSearchLambda)="initializeSearchLambda.emit()"
      (navigatedToResult)="onNavigatedToResult()"></coin-group-search-results>
  </div>
</div>
